import {renderRoutes} from 'react-router-config';
import {ROOT} from '@router/consts';
import BaseLink from '@components/Form/BaseLink/BaseLink';
import Logo from '@assets/icons/Logo';

const SurveyLayout = ({route}) => (
  <div className="bg-main min-h-screen h-full px-12 py-6 flex relative">
    <BaseLink to={ROOT} className="absolute top-10 left-10 cursor-pointer"><Logo /></BaseLink>
    {renderRoutes(route.routes)}
  </div>
);

export default SurveyLayout;
