import React from 'react';
import {renderRoutes} from 'react-router-config';
import {useHistory, useLocation} from 'react-router-dom';
import {Logo} from '@assets/icons';
import {
  ROOT,
  AUTH_ROOT_SIGN_UP,
  AUTH_ROOT_SIGN_IN,
} from '@router/consts';
import {BaseLink} from '@components/Form';

const AuthLayout = ({route}) => {
  const {pathname} = useLocation();
  const {location} = useHistory();

  const viewLogo = (pathname !== AUTH_ROOT_SIGN_IN && pathname !== AUTH_ROOT_SIGN_UP)
    || location.search !== '';

  return (
    <div className="bg-main min-h-screen h-full flex relative">
      {viewLogo && <BaseLink to={ROOT} className="absolute top-10 left-10 cursor-pointer"><Logo /></BaseLink>}
      {renderRoutes(route.routes)}
    </div>
  );
};
export default AuthLayout;
