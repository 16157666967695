import {useContext, useEffect} from 'react';
import {SkeletonTheme} from 'react-loading-skeleton';
import {renderRoutes} from 'react-router-config';
import {isMobile} from 'react-device-detect';

import './styles/main.scss';
import {MEDIA_FONT_SIZE, MEDIA_SCREEN} from '@utils/consts';
import {useRouterPaths} from '@hooks/useRouterPaths';
import {UserContext} from '@contexts/User';
import 'react-loading-skeleton/dist/skeleton.css';
import GlobalNotify from '@components/GlobalNotify/GlobalNotify';
import useResize from '@hooks/useResize';
import Root from '@pages/Root';

const App = () => {
  const {
    profile, surveyStatus, isMainLoading, setHtmlFontSize,
  } = useContext(UserContext);
  // Set role -> get roles by role;
  const routers = useRouterPaths(profile?.type, surveyStatus, isMainLoading);

  const {windowWidth} = useResize();

  const setFontSize = (val) => {
    document.documentElement.style.fontSize = `${val}px`;
    setHtmlFontSize(val);
  };

  useEffect(() => {
    if (windowWidth < MEDIA_SCREEN.SM) {
      setFontSize(MEDIA_FONT_SIZE.XS);
    } else if (windowWidth < MEDIA_SCREEN.LG) {
      setFontSize(MEDIA_FONT_SIZE.SM);
    } else {
      setFontSize(MEDIA_FONT_SIZE.BASE);
    }
  }, [windowWidth]);

  if (isMobile && window.innerWidth < 1100) {
    return (<Root.MobileView isLandscapeAvailable={window.innerHeight >= 1100} />);
  }

  return (
    <>
      <GlobalNotify />
      <SkeletonTheme
        highlightColor="var(--colors-milk)"
        color="#fff"
      >
        {
          routers && renderRoutes(routers)
        }
      </SkeletonTheme>
    </>
  );
};

export default App;
