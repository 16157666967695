import {renderRoutes} from 'react-router-config';
import {useContext, useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';

import Navbar from '@components/Navbar';
import {
  VIDEO_ROOM, APPOINTMENT_DETAIL, APPOINTMENTS_BOOKING, APPOINTMENTS_FREE_TIME,
} from '@router/consts';
import {AFTER_SURVEY_STEPS} from '@utils/consts';
import {UserContext} from '@contexts/User';
import {AppointmentReportContext} from '@contexts/AppointmentReport';
import {AppointmentReportModal} from './components';
import styles from './RootLayout.module.scss';

const RootLayout = ({route}) => {
  const menuItems = useMemo(() => route.routes.filter((item) => item?.isMenu), [route]);

  const isVideoRoom = useRouteMatch(VIDEO_ROOM);
  const isAppointmentDetails = useRouteMatch(APPOINTMENT_DETAIL);
  const isAppointmentBooking = useRouteMatch(APPOINTMENTS_BOOKING);
  const isAppointmentFreeTime = useRouteMatch(APPOINTMENTS_FREE_TIME);

  const {afterSurveyWrapStep, setAfterSurveyWrapStep} = useContext(UserContext);
  const {appointmentReportItem} = useContext(AppointmentReportContext);

  const stepsWrapHandler = () => {
    if (afterSurveyWrapStep === AFTER_SURVEY_STEPS.FIRST_STEP) {
      setAfterSurveyWrapStep(AFTER_SURVEY_STEPS.SECOND_STEP);
    } else {
      setAfterSurveyWrapStep(null);
    }
  };

  return (
    <div className=" min-h-screen h-full max-h-screen flex flex-row relative">
      <AnimatePresence>
        {!!afterSurveyWrapStep
          && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                type: 'easyInOut',
              },
            }}
            exit={{
              opacity: 0,
            }}
            className="bg-black bg-opacity-80 absolute w-full h-full z-10 cursor-pointer"
            onClick={() => stepsWrapHandler()}
          />
          )}
      </AnimatePresence>
      {!isVideoRoom && !(isAppointmentDetails && !isAppointmentBooking && !isAppointmentFreeTime) && <Navbar navItems={menuItems} />}
      <div className={clsx('flex-1 flex items-center justify-center bg-cararra', !isVideoRoom && 'p-12', styles.rootContainer)}>
        {renderRoutes(route.routes)}
      </div>
      <AppointmentReportModal isOpen={!!appointmentReportItem && !afterSurveyWrapStep} />
    </div>
  );
};

export default RootLayout;
