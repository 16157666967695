import {APPOINTMENTS, ORGANIZATION, ACCOUNT_SELECTION} from '@router/consts';
import {Redirect} from 'react-router-dom';
import {useContext} from 'react';
import {UserContext} from '@contexts/User';
import {USER_ROLES} from '@utils/consts';

export const RedirectRoot = () => {
  const {profile} = useContext(UserContext);
  if (profile?.type === USER_ROLES.PRINCIPAL_ROLE) {
    return <Redirect to={ORGANIZATION} />;
  }

  if (profile?.type === USER_ROLES.ALLBRY_COUNSELLOR_ROLE) {
    return <Redirect to={ACCOUNT_SELECTION} />;
  }

  return <Redirect to={APPOINTMENTS} />;
};
