import {useState, useContext} from 'react';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import Lottie from 'react-lottie';

import {CustomModal} from '@components/Modals';
import {Divider} from '@components/Static';
import {ButtonGroup, Button, Slider} from '@components/Form';
import {appointmentReportOptions} from '@utils/appointmentReportData';
import {AppointmentReportContext} from '@contexts/AppointmentReport';
import {SuccessAnimation} from '@assets/lottie';

import styles from './AppointmentReportModal.module.scss';

const AppointmentReportModal = ({isOpen}) => {
  const {t} = useTranslation();
  const {createReport} = useContext(AppointmentReportContext);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [worryLevel, setWorryLevel] = useState('0');

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const onChangeWorryLevel = (event) => {
    setWorryLevel(event.target.value);
  };

  const onSelectedOptionsChange = (option) => {
    let found = false;
    const newOptions = selectedOptions.map((selectedOption) => {
      if (selectedOption.value === option.value) {
        found = true;
        return null;
      }

      return selectedOption;
    }).filter((selectedOption) => !!selectedOption);

    if (!found) {
      newOptions.push(option);
    }

    setSelectedOptions(newOptions);
  };

  const saveAppointmentReport = async () => {
    setIsSaving(true);

    try {
      await createReport({
        data: {
          topics: selectedOptions.map((option) => option.value),
          worryLevel,
        },
      });

      setSelectedOptions([]);
      setWorryLevel(0);
      setIsSaving(false);
      setIsSaved(true);
    } catch (err) {
      setIsSaving(false);
    }
  };

  const closeSavedModal = () => {
    setIsSaved(false);
  };

  const closeModal = () => {
    setIsSaved(false);
  };

  const renderSteps = () => {
    if (isSaved) {
      return (
        <div className="flex flex-col justify-center items-center">
          <div className="w-96 h-56">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: SuccessAnimation,
                rendererSettings: {
                  viewBoxSize: '90 50 760 500',
                },
              }}
              ariaRole="none"
            />
          </div>
          <h1 className="font-bold-rubik text-3xl leading-title text-dawn-gray tracking-tight mb-2">{t('reports.participationThanks.title')}</h1>
          <span className="text-base text-cloudy-gray max-w-xs text-center mb-12">{t('reports.participationThanks.details')}</span>
          <Button variant="edit" className="rounded-full py-4 px-24" onClick={closeSavedModal} isLoading={isSaving}>
            <span className="text-white">
              {t('reports.participationThanks.close')}
            </span>
          </Button>
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <div className="h-28 px-12 flex flex-row items-center">
          <span className="font-bold-rubik text-3xl text-dawn-gray tracking-tight m-0">{t('reports.title')}</span>
        </div>
        <Divider />
        <div className="flex flex-col px-12">
          <div className="pt-9">
            <span className="text-dawn-gray text-xl font-bold">{t('reports.selectTopics')}</span>
            <div className="mt-9">
              <ButtonGroup
                className="flex flex-row flex-wrap gap-2"
                variant="report"
                variantSelected="reportSelected"
                options={appointmentReportOptions}
                selectedOptions={selectedOptions}
                onChange={onSelectedOptionsChange}
              />
            </div>
          </div>
          <div className="mt-11 mb-16">
            <span className="text-dawn-gray text-xl font-bold">{t('reports.concernScale')}</span>
            <div className="mt-6 flex justify-center items-center">
              <Slider className="w-full" value={worryLevel} onChange={onChangeWorryLevel} />
            </div>
          </div>
        </div>
        <div className="absolute left-0 bottom-0 w-full py-7 px-12 z-10 shadow-top flex flex-row justify-end items-center">
          <Button variant="edit" className="rounded-full w-44 py-4 border-transparent border-solid border" onClick={saveAppointmentReport} isLoading={isSaving} isDisabled={!selectedOptions?.length}>
            <span className="text-white">{t('common.save')}</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <CustomModal
      openModal={isOpen || isSaved}
      setOpenModal={closeModal}
      hideCloseButton={!isSaved}
      className={clsx('bg-milk rounded-xl', styles.reportModal, isSaved ? 'p-14' : 'pb-28')}
    >
      {renderSteps()}
    </CustomModal>
  );
};

export default AppointmentReportModal;
