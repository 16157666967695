import {
  useContext, useState, useEffect, useMemo,
} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {format} from 'date-fns';
import {
  Logo, User, Settings, Logout, SelectArrow,
} from '@assets/icons';
import {BaseLink} from '@components/Form';
import {
  PROFILE, SETTINGS, APPOINTMENTS, ROOT,
} from '@router/consts';
import {sendNotify} from '@components/GlobalNotify/GlobalNotify';
import {Divider, Popup} from '@components/Static';
import {UserContext} from '@contexts/User';
import {useProfileImageUrl} from '@hooks/profile';
import {useFetchHook} from '@hooks/useFetchHook';
import ProfilePicture from '@components/ProfilePicture';
import NavPopupButton from '@components/Static/Popup/NavPopupButton';
import NavbarList from '@components/Navbar/NavbarList';
import {USER_ROLES, MEDIA_FONT_SIZE, USER_STATUSES} from '@utils/consts';
import Loader from '@components/Loader';
import DistributedUsersService from '@services/api/distributedUsers';
import AccountService from '@services/api/account';

import styles from './Navbar.module.scss';

const Navbar = ({
  className, navItems,
}) => {
  const {t} = useTranslation();

  const {
    logout, profile, updateCurrentProfile, htmlFontSize, user, switchAccountTokens,
  } = useContext(UserContext);
  const [sendFetch, {isLoading}] = useFetchHook(updateCurrentProfile);

  const profilePictureUrl = useProfileImageUrl(profile.profilePictureName, profile.accountId);
  const [isDistributedLoading, setIsDistributedLoading] = useState(false);
  const [distributedAccounts, setDistributedAccounts] = useState([]);
  const [isSwitchInProgress, setIsSwitchInProgress] = useState(false);

  const isAllbryCounsellor = user.roles.some((role) => role.name === USER_ROLES.ALLBRY_COUNSELLOR_ROLE);
  const isCounsellor = profile.type === USER_ROLES.COUNSELLOR_ROLE;
  const now = Date.now();

  useEffect(async () => {
    if (!isAllbryCounsellor) {
      return;
    }
    setIsDistributedLoading(true);

    const distributedUsersResponse = await DistributedUsersService.getDistributedUsers({userId: profile.userId});
    const distributedUsers = distributedUsersResponse.filter((distributedUser) => distributedUser.status === USER_STATUSES.ACTIVE);
    const accountsPromises = distributedUsers.map((distributedUser) => AccountService.getAccountById(distributedUser.distributedAccountId));

    await Promise.all(accountsPromises).then((accounts) => setDistributedAccounts(accounts.filter((account) => now >= Number(account.dtActivation) && now < Number(account.dtDeactivation))));

    setIsDistributedLoading(false);
  }, [isAllbryCounsellor]);

  if (!profile) {
    return <div className="h-screen w-36 flex justify-center items-center"><Loader height={60} width={60} /></div>;
  }

  const PopupTrigger = (__open) => (
    <div className={clsx('rounded-full w-18 h-17 cursor-pointer bg-silver-light')}>
      <ProfilePicture profilePictureUrl={profilePictureUrl} profile={profile} profilePictureStyle={{height: 17, className: 'w-18 h-17'}} isShowOnline alt="profile" />
    </div>
  );

  const SwitchAccountTrigger = (__open) => (
    <div className={clsx('flex rounded-md items-center relative justify-center mx-4 py-4 cursor-pointer mb-12', __open && 'bg-opacity-70 bg-pampas')}>
      <Logo />
      <div className="absolute right-1 my-auto">
        <SelectArrow />
      </div>
    </div>
  );

  const onLogout = () => {
    logout();
  };

  const onSwitchAccount = async (id) => {
    if (id === user?.accountId) {
      return;
    }

    try {
      setIsSwitchInProgress(true);
      await switchAccountTokens(id);
      window.location.replace(ROOT);
    } catch (e) {
      setIsSwitchInProgress(false);
      sendNotify(t('common.switchAccountError'), 'error');
    }
  };

  const handleOnline = () => {
    if (!isLoading) {
      const updatedProfile = {...profile, birthday: format(new Date(profile.birthday), 'yyyy-MM-dd'), isOnline: !profile.isOnline};
      sendFetch(updatedProfile);
    }
  };

  const UserIcon = () => <User className="fill-current text-dawn-gray" />;
  const AllbryIcon = () => <Logo size="h-10 w-10" />;

  const renderAccounts = useMemo(() => (!isDistributedLoading && distributedAccounts.length && !isSwitchInProgress
    ? (
      distributedAccounts.map((account) => (
        <NavPopupButton
          key={account.id}
          Icon={AllbryIcon}
          text={account.name}
          className={account.id === user.accountId && 'bg-pampas'}
          textClassName="max-w-30 truncate"
          onClick={() => onSwitchAccount(account.id)}
          isSelected={account.id === user.accountId}
        />
      ))
    )
    : <div className="h-20 flex items-center"><Loader height={40} width={40} /></div>), [isDistributedLoading, distributedAccounts, user, isSwitchInProgress]);

  return (
    <div className={clsx(styles.nav, className)}>
      <div className={clsx('w-full flex flex-col', isAllbryCounsellor ? 'pt-4' : 'pt-8')}>
        {isAllbryCounsellor
          ? (
            <Popup
              variant="accounts"
              position={['right']}
              trigger={SwitchAccountTrigger}
            >
              <>{renderAccounts}</>
            </Popup>
          )
          : (
            <BaseLink to={APPOINTMENTS} className="flex items-center justify-center w-full cursor-pointer mb-16">
              <Logo />
            </BaseLink>
          ) }
        <NavbarList navItems={navItems} />
      </div>
      <div className="pb-16">
        <Popup
          variant="navbar"
          position={['right']}
          trigger={PopupTrigger}
          offsetY={(htmlFontSize / MEDIA_FONT_SIZE.BASE) * (isCounsellor ? -108 : -56)}
        >
          <>
            {isCounsellor && (
            <>
              <div onClick={handleOnline} className="flex w-full my-2 py-5 pl-5 rounded-md text-lg text-dawn-gray tracking-tight hover:bg-pampas hover:bg-opacity-50 cursor-pointer">
                {t('navbar.switch')}
                  &nbsp;
                <span className={clsx(!profile.isOnline ? 'text-jungle-green' : 'text-stone')}>{profile.isOnline ? t('navbar.toOffline') : t('navbar.toOnline')}</span>
              </div>
              <Divider className="mb-5" />
            </>
            )}
            <NavPopupButton Icon={UserIcon} path={`${PROFILE}/${profile.id}`} label="navbar.profile" />
            <NavPopupButton Icon={Settings} path={SETTINGS} label="navbar.settings.title" />
            <NavPopupButton Icon={Logout} label="navbar.logOut" textClassName="text-pepper" onClick={onLogout} />
          </>
        </Popup>
      </div>
    </div>
  );
};

export default Navbar;
